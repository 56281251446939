<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Bildirimler"
      icon="BellIcon"
      :showLoading="show"
      :actionCollapse="false"
      :actionRefresh="true"
      :actionNewAdd="false"
      :collapsed="false"
      @refresh="refreshStop('cardAction')"
      @newAdd="handlerNewAdd($event)"
    >
      <template v-slot:filtrele> </template>
      <template v-slot:body>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: search,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 100,
            mode: 'pages',
          }"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'tarih'">
              <b-badge fill variant="secondary" class="rounded-0 mr-25">
                {{ props.row.tarih | momentFull }}
              </b-badge>
            </div>
            <div v-else-if="props.column.field === 'islem_k_no'">
              <b-badge fill variant="light-warning" class="rounded-0 mr-25">
                {{ props.row.islem_k_no }}
              </b-badge>
            </div>
            <span v-else-if="props.column.field === 'statu'">
              <b-form-checkbox
                class="custom-control-primary"
                v-model="props.row.statu"
                switch
                @change="handlerStatu(props.row.k_no, $event)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="BellIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="BellOffIcon" />
                </span>
              </b-form-checkbox>
            </span>
            <span v-else-if="props.column.field === 'durum'">
              {{ props.row.statu == true ? 'Görüldü' : 'Görülmedi' }}
            </span>
            <span v-else-if="props.column.field === 'action'">
              <b-button squared size="sm" variant="danger" class="btn-icon">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <template slot="pagination-bottom">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <v-select
                  v-model="pageSize"
                  :options="perPageOptions"
                  :clearable="false"
                  class="invoice-filter-select"
                  @input="handlerPageSizeChange"
                />
                <span class="pl-2"> Toplam Kayıt : {{ count }}</span>
              </div>
              <div>
                <b-pagination
                  :total-rows="count"
                  :per-page="pageSize"
                  v-model="page"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handlerPageChange"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </template>
    </b-card-actions>
  </div>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BCardNormal,
    BCardActions,
    VueGoodTable,
    vSelect,
  },
  computed: {},
  data() {
    return {
      show: false,
      closeModal: false,
      updateData: {},
      openModal: false,

      count: 0,
      page: 1,
      pageSize: 25,
      perPageOptions: [5, 10, 25, 50, 100],
      search: '',
      columns: [
        {
          label: 'Tarih',
          field: 'tarih',
        },
        {
          label: 'Işlem K No',
          field: 'islem_k_no',
        },
        {
          label: 'Mesaj',
          field: 'mesaj',
        },
        {
          label: 'Statu',
          field: 'statu',
        },
        {
          label: 'Durum',
          field: 'durum',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      bakiye: [],
    };
  },
  created() {
    this.handlerOdemeler();
  },
  methods: {
    async handlerOdemeler() {
      this.show = true;
      await this.$store.dispatch('bildirimListele').then((res) => {
        this.count = res.data.count;
        this.rows = res.data.data;
      });
      this.show = false;
    },
    handlerFilters(event) {
      this.handlerOdemeler();
    },
    refreshStop(event) {
      this.handlerOdemeler();
    },
    // handlerFilterClear() {
    //   this.filter = {
    //     k_no: null,
    //     tarih_turu: 1,
    //     bas_tarih: null,
    //     bit_tarih: null,
    //     siralama: 1,
    //     statu: [],
    //     odeme: [],
    //   };
    //   this.handlerOdemeler();
    // },
    handlerNewAdd() {
      this.openModal = true;
    },
    handlerUpdate(task) {
      if (task) {
        this.updateData = task;
        this.openModal = true;
      }
    },
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('odemeSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
            }
          });
        }
      });
    },
    handlerPageChange(event) {
      this.page = event;
      this.handlerOdemeler();
    },
    handlerPageSizeChange(value) {
      this.pageSize = value;
      // this.page = 1;
      this.handlerOdemeler();
    },
  },
  watch: {
    closeModal(val) {
      if (val == false) {
        this.openModal = false;
        this.updateData = {};
        this.handlerOdemeler();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
